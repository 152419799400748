
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { DataList, PersonList } from '@/types/scheduling'
import { pInteger } from '@/utils/validate'

@Component

export default class ScheduleAdd extends Vue {
  private disabled = true
  private workerList: Array<{ workerId: string; workerName: string }> = []
  private workTimeList: Array<{ time: string; type: string }> = []
  private workTime = ''
  private info: DataList = {
    projectId: '',
    // 排班日期
    schedulingDateList: [],
    // 排班人数
    schedulingSum: '',
    // 排班总人数
    workerAmount: '',
    // 绑定设备数
    deviceAmount: ''
  }

  private tableData: PersonList[] = []
  private operation = false

  private rules = {
    projectId: [
      { required: true, message: '请选择项目名称', trigger: ['blur', 'change'] }
    ],
    schedulingDateList: [
      { required: true, message: '请输入排班日期', trigger: ['blur', 'change'] },
      { validator: this.validateMaxNum, trigger: ['blur', 'change'] }
    ],
    schedulingSum: [
      { required: true, message: '请输入排班人数', trigger: ['blur', 'change'] },
      { validator: pInteger, trigger: ['blur', 'change'] },
      { validator: this.validateSum, trigger: ['blur', 'change'] }
    ]
  }

  private pickerOptions = {}

  get projectList () {
    return this.$store.state.projectList
  }

  // 在岗时间段
  get workTimeInfo () {
    let data = this.workTimeList.length > 0 ? '' : '--'
    this.workTimeList.forEach(item => {
      data += '时间段' + item.type + ':&nbsp;' + item.time + '&nbsp;&nbsp;&nbsp;'
    })
    return data
  }

  get id () {
    return this.$route.params.id || ''
  }

  created () {
    this.id && this.getDetail()
  }

  validateSum (rule: any, value: number, callback: Function) {
    if (this.info.workerAmount && value > +this.info.workerAmount) {
      callback(new Error('不能超过项目总人数'))
    } else {
      return callback()
    }
  }

  validateMaxNum (rule: any, value: Array<string>, callback: Function) {
    if (value && value.length > 30) {
      callback(new Error('添加日期不超过30天'))
    } else {
      return callback()
    }
  }

  selectProject (value: string) {
    this.info.schedulingDateList = []
    this.info.schedulingSum = ''
    this.operation = false
    this.tableData = []
    this.getWorkerList()
    this.getWorkTimeListByProjectId()
    this.$axios.get(this.$apis.worker.selectProjectSchedulingByList, {
      projectId: value
    }).then(res => {
      this.info.workerAmount = res.workerAmount || '0'
      this.info.deviceAmount = res.deviceAmount || '0'
      if (this.info.workerAmount !== '0') {
        this.disabled = false
      } else {
        this.disabled = true
      }
      this.pickerOptions = {
        disabledDate: (time: Date) => {
          // 大于当前日期禁止选择
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000 || res.schedulingDateList.indexOf(this.$dayjs(time).format('YYYY-MM-DD')) !== -1
        }
      }
    })
  }

  // 查询详情
  getDetail () {
    this.$axios.get(this.$apis.worker.selectProjectSchedulingById, {
      id: this.id
    }).then(res => {
      this.info.schedulingDateList = res.schedulingDate || []
      this.info.projectId = res.projectId
      this.info.schedulingSum = res.schedulingSum
      this.info.workerAmount = res.workerAmount
      this.info.deviceAmount = res.deviceAmount
      this.workTime = (res.workTime1 ? '时间段1: ' + res.workTime1 + '&nbsp;&nbsp;&nbsp;' : '--') +
       (res.workTime2 ? '时间段2: ' + res.workTime2 + '&nbsp;&nbsp;&nbsp;' : '--') +
       (res.workTime3 ? '时间段3: ' + res.workTime3 + '&nbsp;&nbsp;&nbsp;' : '--')
      this.tableData = res.projectSchedulingWorkerList
      // 编辑
      if (this.id) {
        this.info.id = this.id
      }
      this.getWorkerList()
      this.getWorkTimeListByProjectId()
    })
  }

  // 查询项目工人列表
  getWorkerList () {
    this.$axios.get(this.$apis.worker.selectWorkerList, {
      projectId: this.info.projectId
    }).then(res => {
      this.workerList = res || []
    })
  }

  // 查询项目在岗班次列表
  getWorkTimeListByProjectId () {
    this.$axios.get(this.$apis.worker.selectWorkTimeListByProjectId, {
      projectId: this.info.projectId
    }).then(res => {
      this.workTimeList = res || []
    })
  }

  // 不能重复的姓名
  getExtraWorkerList (rowIndex: number) {
    const list: any[] = []
    this.tableData.forEach((item, index) => {
      if (rowIndex !== index) {
        item.workerIdList.forEach((workerId: string) => {
          list.push(workerId)
        })
      }
    })
    return this.workerList.filter((item) => {
      return list.every((item1) => {
        return item.workerId !== item1
      })
    })
  }

  addPerson () {
    if (!this.info.projectId) {
      this.$message.warning('先选择项目')
    } else if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      this.tableData.push({
        workerIdList: [],
        workTimeList: [],
        isEdit: true
      })
      this.$nextTick(() => {
        ;(this.$refs.table as any).bodyWrapper.scrollTop = (this.$refs
          .table as any).bodyWrapper.scrollHeight
      }) // 滚动到表格最底部
    }
  }

  getWorkTable (list: Array<string>) {
    const workers = this.workerList.filter((item) => {
      return list.indexOf(item.workerId) !== -1
    })
    let data = ''
    workers.forEach(item => {
      data += item.workerName + ', '
    })
    return {
      data: data,
      length: workers.length
    }
  }

  getWorkTimeTable (list: Array<string>) {
    const workers = this.workTimeList.filter((item) => {
      return list.indexOf(item.type) !== -1
    })
    let data = ''
    workers.forEach(item => {
      data += item.time + ' '
    })
    return data
  }

  onSave (row: PersonList, index: number) {
    ;(this.$refs.row as ElForm).validate(valid => {
      ;(this.$refs.workTimeList as ElForm).validate(valid1 => {
        if (valid && valid1) {
          this.operation = false
          delete this.tableData[index].isEdit
          this.$set(this.tableData, index, row)
        }
      })
    })
  }

  onEdit (row: PersonList, index: number) {
    if (this.operation) {
      this.$message.warning('请先保存数据')
    } else {
      this.operation = true
      this.tableData[index].isEdit = true
      this.$set(this.tableData, index, row)
    }
  }

  onDelete (index: number) {
    this.tableData.splice(index, 1)
    this.operation = false
  }

  selectAll (row: PersonList, index: number) {
    const arr = this.getExtraWorkerList(index)
    row.workerIdList = arr.map(item => {
      return item.workerId
    })
    this.$set(this.tableData, index, row)
  }

  cancelSelectAll (row: PersonList, index: number) {
    row.workerIdList = []
    this.$set(this.tableData, index, row)
  }

  submit (callback: Function) {
    ;(this.$refs.info as ElForm).validate(valid => {
      if (valid) {
        if (this.operation) {
          this.$message.warning('请先保存')
          return false
        }
        const data = JSON.parse(JSON.stringify(this.info))
        delete data.workerAmount
        delete data.deviceAmount
        if (this.id) {
          delete data.schedulingDateList
          delete data.projectId
          data.id = this.id
        }
        this.$axios.post(this.id ? this.$apis.worker.updateProjectScheduling : this.$apis.worker.insertAllProjectScheduling, {
          ...data,
          projectSchedulingWorkerList: this.tableData
        }).then(() => {
          this.$message.success('保存成功')
          callback()
        })
      }
    })
  }

  onSubmit () {
    this.submit(() => {
      this.$router.push({ name: 'scheduleList' })
    })
  }

  onSubmit1 () {
    this.submit(() => {
      (this.$refs.info as ElForm).resetFields()
      this.tableData = []
      this.disabled = true
    })
  }
}
